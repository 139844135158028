<template>
  <b-card>
    <b-form>
      <validation-observer ref="simpleRules">
        <form @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group id="input-group-1" label="Name" label-for="input-name">
                <validation-provider #default="{ errors }" name="Name" rules="required">
                  <b-form-input id="input-name" v-model="form.name" type="text" placeholder="User name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group id="input-group-2" label="Email" label-for="input-email">
                <validation-provider #default="{ errors }" name="Email" rules="required|email">
                  <b-form-input id="input-email" v-model="form.email" type="text" placeholder="User email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="id" cols="6">
              <b-form-group id="input-group-3" label="Password" label-for="input-password">
                <b-input-group>
                  <b-form-input id="input-password" v-model="form.password" :type="passwordFieldType"
                    placeholder="Password" />
                  <b-input-group-append>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary"
                      @click="togglePasswordVisibility">
                      <feather-icon :icon="passwordToggleIcon" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <b-button @click="generatePassword" class="mt-1" size="md" block variant="outline-primary">
                  Generate Password
                </b-button>
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group id="input-group-4" label="Avatar" label-for="input-avatar">
                <b-form-input id="input-avatar" v-model="form.avatar" type="text" placeholder="Avatar URL" />
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <b-form-group label="Role">
                <validation-provider #default="{ errors }" name="Role" rules="required">
                  <v-select v-model="form.role" :options="roles" label="name" :state="errors.length > 0 ? false : null"
                    class="w-100" :clearable="false" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" @click="postUsers()">
                Enviar
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$emit('close')">
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard
} from 'bootstrap-vue'
import UserController from '@/controllers/UserController'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

const { log } = require('@/utils/log')

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    required,
    email
  },

  directives: {
    Ripple,
  },

  data() {
    return {
      form: {
        name: "",
        email: "",
        password: "",
        confirm: "",
        avatar: "",
        role: "",
      },
      searchRoles: "",
      id: "",
      passwordFieldType: "password"
    };
  },

  computed: {
    roles() {
      return this.$store.state.roleConfig.roles
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },

  async created() {
    this.$store.dispatch('getAllRoles')
  },

  async mounted() {
    const id = this.$route.params.id;
    this.id = id;
    if (this.$route.name === 'users/edit') {
      const response = await this.searchUser(id)
      if (response === 404) return this.$router.push('/error-404')
      else {
        this.form.name = response.name
        this.form.email = response.email
        this.form.password = response.password
        this.form.confirm = response.password
        this.form.avatar = response.avatar
        this.form.role = response.roles[0]
      }
    }
  },

  methods: {
    generatePassword() {
      const lowerCase = "abcdefghijklmnopqrstuvwxyz";
      const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      const number = "0123456789";
      const specialCharacter = "!@#$%^&*()_+~`|}{[]:;?><,./-=";
      const all = lowerCase + upperCase + number + specialCharacter;
      const passwordLength = 8;
      let password = "";
      for (let index = 0; index < passwordLength; index++) {
        const character = Math.floor(Math.random() * all.length);
        password += all.substring(character, character + 1);
      }
      this.form.password = password;
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
    async searchUser(id) {
      try {
        const userController = new UserController()
        return await userController.getUser(id)
      } catch (error) {
        log(error)
        return error.response.status
      }
    },

    async postUsers() {
      const isValid = await this.$refs.simpleRules.validate();
      if (isValid) {
        const user = {
          name: this.form.name,
          email: this.form.email,
          avatar: this.form.avatar,
          password: this.form.password,
          fk_role: this.form.role.id,
        };

        try {
          const userController = new UserController();
          if (this.$route.name != 'users/edit') await userController.postUser(user)
          else {
            user.id = this.id
            await userController.postUser(user)
          }

          this.$emit('close')
        } catch (error) {
          log(error)
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
